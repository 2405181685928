<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-margin">
            <div uk-grid class="uk-grid-small">
                <div>
                    <div class="uk-inline">
                        <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
                        <input class="uk-input" @keyup.enter="(e) => changeMeta(e, 'email')" type="text" placeholder="Search">
                    </div>
                </div>
                <div>
                    <div v-if="meta.type === 'myrobin' || meta.type === 'all'" class="uk-form-controls uk-width-small">
                        <select class="uk-select" id="form-horizontal-select" v-model="meta.status" @change="(e) => changeMeta(e, 'status')">
                            <option :value="''">Select status</option>
                            <option :value="1">Live</option>
                        </select>
                    </div>
                    <div v-else class="uk-form-controls uk-width-small">
                        <select class="uk-select" id="form-horizontal-select" v-model="meta.status" @change="(e) => changeMeta(e, 'status')">
                            <option :value="''">Select status</option>
                            <option :value="'invalid'">Invalid</option>
                            <option :value="'reject'">Rejected</option>
                            <option :value="'valid'">Valid</option>
                        </select>
                    </div>
                </div>
                <div>
                    <div class="uk-form-controls uk-width-small">
                        <select class="uk-select" id="form-horizontal-select" v-model="meta.type" @change="(e) => changeMeta(e, 'type')">
                            <option :value="'all'">All</option>
                            <option :value="'myrobin'">Myrobin</option>
                            <option :value="'instajob'">Instajob</option>
                        </select>
                    </div>
                </div>
                <div>
                    <div class="uk-form-controls uk-width-small">
                        <select class="uk-select" id="form-horizontal-select" v-model="meta.size_company" @change="(e) => changeMeta(e, 'size_company')">
                            <option :value="''">Select Size</option>
                            <option :value="'Big'">Big</option>
                            <option :value="'Medium'">Medium</option>
                            <option :value="'Small'">Small</option>
                        </select>
                    </div>
                </div>
                <!-- <div class="uk-width">
                    <div class="uk-text-right">
                        <button class="uk-button uk-button-success">Create Job</button>&#160;
                        <button class="uk-button uk-button-primary" @click="goToCreatePost">Create Instajob</button>
                    </div>
                </div> -->
            </div>
        </div>

        <div class="uk-card uk-card-default uk-margin">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-small uk-table-divider uk-table-striped">
                    <thead>
                        <tr>
                            <th>Nama Bisnis</th>
                            <th>Posisi yang Dibuka</th>
                            <th>Lokasi Pekerjaan</th>
                            <th>Email</th>
                            <th>No Telepon 1</th>
                            <th>No Telepon 2</th>
                            <th>Size Bisnis</th>
                            <th>Komentar</th>
                            <th>Image Text</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="jobs.docs.length>0">
                        <tr v-for="(job, i) in jobs.docs" :key="i">
                            <td v-if="job.type === 'myrobin'">{{job.company ? job.company.name : '-'}}</td>
                            <td v-else>{{job.company || '-'}}</td>
                            <td>{{job.title || '-'}}</td>
                            <td>{{job.address || '-'}}</td>
                            <td>{{job.email || '-'}}</td>
                            <td>{{job.phone || '-'}}</td>
                            <td>{{job.second_phone || '-'}}</td>
                            <td>{{job.size_company || '-'}}</td>
                            <td>{{job.comment || '-'}}</td>
                            <td>{{job.image_text || '-'}}</td>
                            <td>
                                <label-status v-if="job.type === 'myrobin'" :type="'job'" :status="job.status" />
                                <label-status v-if="job.type === 'instajob'" :type="'instajob'" :status_string="job.status" />
                            </td>
                            <td>
                                <div v-if="job.type === 'myrobin'">
                                    <button
                                        style="width:100px; cursor:pointer; height:30px; margin-bottom:5px; background:#333747"
                                        class="uk-button-primary"
                                        v-clipboard:copy="job._id"
                                        v-clipboard:success="onCopy"
                                    >Copy Id</button><br/>
                                    <!-- <button style="width:100px; height:30px; margin-bottom:5px" class="uk-button-default" @click="showModalImage(job)">Lihat Gambar</button><br/> -->
                                    <button style="width:100px; cursor:pointer; height:30px; margin-bottom:5px" class="uk-button-primary" @click="jobAction(job._id, 'approve')">Approve</button><br/>
                                    <button style="width:100px; cursor:pointer; height:30px;" class="uk-button-danger" @click="showModalReject(job._id)">Reject</button>
                                </div>
                                <div v-else>
                                     <button
                                        style="width:100px; cursor:pointer; height:30px; margin-bottom:5px; background:#333747"
                                        class="uk-button-primary"
                                        v-clipboard:copy="job._id"
                                        v-clipboard:success="onCopy"
                                    >Copy Id</button><br/>
                                    <!-- <button style="width:100px; height:30px; margin-bottom:5px" class="uk-button-default" @click="showModalImage(job)">Lihat Gambar</button><br/> -->
                                    <button style="width:100px; cursor:pointer; height:30px; margin-bottom:5px" class="uk-button-primary" @click="statusPost(job.id, 'valid')">Verify</button><br/>
                                    <button style="width:100px; cursor:pointer; height:30px; margin-bottom:5px" class="uk-button-danger" @click="statusPost(job.id, 'reject')">Reject</button><br/>
                                    <button style="width:100px; cursor:pointer; height:30px; background:#ffa51f" class="uk-button-default" @click="showEditModal(job)">Edit</button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="11"/>
                </table>
            </div>
            <pagination
                :total-data="jobs.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>

        <div id="edit-modal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideEditModal"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Edit</h2>
                </div>
                <div class="uk-modal-body">
                    <label class="uk-form-label">Industry</label>
                    <div class="uk-width-1-1 uk-inline">
                        <multiselect
                            v-model="industry_value"
                            placeholder="Search or add industry"
                            label="name"
                            track-by="name"
                            :options="industry_options"
                            :multiple="true"
                            @tag="addIndustryOption">
                        </multiselect>
                    </div>
                    <br />
                    <br />
                    <label class="uk-form-label">Email</label>
                    <div class="uk-width-1-1 uk-inline">
                        <input
                            class="uk-input"
                            type="email"
                            v-model="post_detail.email"
                            placeholder="input email"
                        >
                    </div>
                    <label class="uk-form-label">Phone</label>
                    <div class="uk-width-1-1 uk-inline">
                        <input
                            class="uk-input"
                            type="number"
                            v-model="post_detail.phone"
                            placeholder="input phone"
                        >
                    </div>
                    <label class="uk-form-label">Phone 2</label>
                    <div class="uk-width-1-1 uk-inline">
                        <input
                            class="uk-input"
                            type="number"
                            v-model="post_detail.second_phone"
                            placeholder="input phone"
                        >
                    </div>
                    <label class="uk-form-label">Address</label>
                    <div class="uk-width-1-1 uk-inline">
                        <v-select
                            :options="City"
                            :filterable="false"
                            v-model="post_detail.city"
                            placeholder="Cari.."
                            @search="query => search = query"
                            >
                        </v-select>
                    </div>
                    <label class="uk-form-label">Skills</label>
                    <div class="uk-width-1-1 uk-inline">
                        <input
                            class="uk-input"
                            type="text"
                            v-model="post_detail.skills"
                            placeholder="input skills"
                        >
                    </div>
                    <label class="uk-form-label">Nama Bisnis</label>
                    <div class="uk-width-1-1 uk-inline">
                        <input
                            class="uk-input"
                            type="text"
                            v-model="post_detail.company"
                            placeholder="input company"
                        >
                    </div>
                    <label class="uk-form-label">Size Bisnis</label>
                    <div class="uk-width-1-1 uk-inline">
                        <select
                            class="uk-select"
                            id="form-horizontal-select"
                            v-model="post_detail.size_company"
                            >
                            <option :value="''">Select size</option>
                            <option :value="'Big'">Big</option>
                            <option :value="'Medium'">Medium</option>
                            <option :value="'Small'">Small</option>
                        </select>
                    </div>
                    <label class="uk-form-label">Posisi yang Dibuka</label>
                    <div class="uk-width-1-1 uk-inline">
                        <input
                            class="uk-input"
                            type="text"
                            v-model="post_detail.title"
                            placeholder="input title"
                        >
                    </div>
                    <label class="uk-form-label">Caption</label>
                    <div class="uk-width-1-1 uk-inline">
                        <textarea
                            rows="5"
                            class="uk-textarea"
                            v-model="post_detail.caption"
                            placeholder="input caption"
                        ></textarea>
                    </div>
                    <label class="uk-form-label">Komentar</label>
                    <div class="uk-width-1-1 uk-inline">
                        <textarea
                            rows="5"
                            class="uk-textarea"
                            v-model="post_detail.comment"
                            placeholder="input comment"
                        ></textarea>
                    </div>
                    <label class="uk-form-label">Image Text</label>
                    <div class="uk-width-1-1 uk-inline">
                        <textarea
                            rows="5"
                            class="uk-textarea"
                            v-model="post_detail.image_text"
                            placeholder="input comment"
                        ></textarea>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-default" type="button" @click="saveEditModal">Save</button>
                </div>
            </div>
        </div>

        <div id="job-reject" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Job Rejected</h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-margin">
                        <label class="uk-form-label">Reason</label>
                        <div class="uk-width-1-1 uk-inline">
                            <input
                                class="uk-input"
                                type="text"
                                placeholder="Reason rejected"
                                v-model="reason"
                                name="reason"
                                v-validate="'required'"
                                :class="{'uk-form-danger': errors.has('reason')}">
                        </div>
                        <span v-show="errors.has('reason')" class="uk-text-small uk-text-danger">{{ errors.first('reason') }}</span>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-default" type="button" @click="hideModal">Cancel</button>
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="submitRejectJob">Reject</button>
                </div>
            </div>
        </div>

        <div id="image-modal" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModalImage"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Image</h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-margin">
                        <img :src="`${job_detail.image}`" alt="" class="uk-margin-bottom" width="600">
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-default" type="button" @click="hideModalImage">Ok</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/utils/api';
import { API_JOB } from '@/utils/api-url';
import { INSTAJOB_API_POST } from '@/utils/api-url';
import formatter from "@/utils/formatter";
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import Pagination from '@/components/globals/Pagination';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import LabelStatus from '@/components/globals/LabelStatus';
import { mapActions, mapGetters } from 'vuex';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import axios from "axios";

export default {
    data() {
        return {
            meta: {
                status: '',
                size_company: '',
                limit: 100,
                page: 1,
                type: 'all'
            },
            selected: [],
            selectAll: false,
            select_job: null,
            reason: null,
            job_detail: {
                image: ''
            },
            post_detail: {
                email: '',
                phone: '',
                second_phone: '',
                city: '',
                skills: '',
                company: '',
                title: '',
                caption: '',
                size_company: '',
                comment: ''
            },
            industry_value: [],
            industry_options: [],
            city: [],
            limit: 50,
            search: ''
        };
    },
    components: {
        Pagination,
        EmptyTable,
        LabelStatus,
        vSelect
    },
    computed : {
        ...mapGetters({
            jobs: 'job/jobs_combine',
            job: 'job/job',
        }),
        filteredCity() {
            return this.city.filter(city => city.toLowerCase().includes(this.search));
        },
        City() {
            return this.filteredCity.slice(0, this.limit);
        },
    },
    watch: {
        meta() {
            this.setJobMeta();
        },
    },
    mounted() {
        this.setJobMeta();
        this.dataCity();
        this.apiIndustry();
    },
    methods: {
        ...mapActions({
            getJob: 'job/getJobCombine'
        }),
        fullName(data) {
            return formatter.getFullname(data);
        },
        setJobMeta() {
            this.getJob(this.meta);
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        async showModalReject(job_id) {
            this.select_job = job_id;
            await window.UIkit.modal('#job-reject').show();
        },
        async hideModal() {
            await window.UIkit.modal('#job-reject').hide();
            this.select_job = null;
            this.reason = null;
            this.$validator.reset();
        },
        async hideModalImage() {
            await window.UIkit.modal('#image-modal').hide();
        },
        submitRejectJob() {
            this.$validator.validateAll().then((success) => {
                if(success) {
                    if (this.$validator.errors.any()) return;
                    this.jobAction(null, 'reject');
                }
            });
        },
        async jobAction(data, action) {
            try {
                await api.apiPatchAuth(API_JOB.ACTION(data || this.select_job, action), {reject_reason: this.reason});
                notificationSuccess('Success update job');
                this.hideModal();
                this.getJob(this.meta);
            } catch (err) {
                notificationDanger(err);
            }
        },
        onCopy(e) {
            notificationSuccess('Copied to clipboard: ' + e.text);
        },
        async showModalImage(job) {
            this.job_detail = job;
            await window.UIkit.modal('#image-modal').show();
        },
        select() {
            this.selected = [];
            if (!this.selectAll) {
                for (let i in this.jobs.docs) {
                    this.selected.push(this.jobs.docs[i]._id);
                }
            }
            this.selectAll = true;
        },
        goToCreatePost() {
            this.$router.push('/admin/job-combine/createInstajob');
        },
        async showEditModal(job) {
            this.industry_value = job.industry.map(dat => {return {name: dat};});
            this.post_detail = job;
            await window.UIkit.modal('#edit-modal').show();
        },
        async hideEditModal() {
            await window.UIkit.modal('#edit-modal').hide();
        },
        async saveEditModal() {
            this.post_detail = {
                ...this.post_detail,
                industry: this.industry_value.map(dat => dat.name),
            };
            try {
                await api.apiPutAuth(INSTAJOB_API_POST.UPDATE(this.post_detail.id), this.post_detail);
                notificationSuccess('Instajob updated');
                this.getJob(this.meta);
                await window.UIkit.modal('#edit-modal').hide();
            } catch (err) {
                notificationDanger(err);
            }
        },
        async statusPost(id, status) {
            try {
                await api.apiPatchAuth(INSTAJOB_API_POST.CHANGE_STATUS(id, status));
                notificationSuccess('Status changed !');
                this.getJob(this.meta);
            } catch (err) {
                notificationDanger(err);
            }
        },
        addEducationOption (newTag) {
            const tag = {
                name: newTag
            };
            this.education_options.push(tag);
            this.education_value.push(tag);
        },
        addGenderOption (newTag) {
            const tag = {
                name: newTag
            };
            this.gender_options.push(tag);
            this.gender_value.push(tag);
        },
        addJobTypeOption (newTag) {
            const tag = {
                name: newTag
            };
            this.job_type_options.push(tag);
            this.job_type_value.push(tag);
        },
        addIndustryOption (newTag) {
            const tag = {
                name: newTag
            };
            this.industry_options.push(tag);
            this.industry_value.push(tag);
        },
        dataCity() {
            axios.get('/static/kota-kabupaten.json').then(res => {
                this.city = res.data;
            }).catch((err) => {
                console.error(err);
            });
        },
        apiIndustry() {
            axios.get('https://stagingapi.myrobin.id/api/v2/company/industry').then(res => {
                this.industry_options = res.data.result;
            }).catch((err) => {
                console.error(err);
            });
        },
    },
};
</script>
